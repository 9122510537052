@tailwind base;
@tailwind components;
@tailwind utilities;

body,
* {
  margin: 0;
  padding: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&family=Work+Sans:ital,wght@0,100;0,200;0,700;1,100;1,200&display=swap");

.text-shadow {
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
}

a {
  @apply cursor-pointer text-[#FE7E55] hover:text-[#ccc];
}

.nav a {
  @apply text-white hover:text-[#FE7E55];
}

h1 {
  @apply text-2xl mb-5;
}

h3 {
  @apply font-medium;
}

p {
  @apply pb-5;
}

.section {
  @apply max-w-screen-md mx-auto w-full h-screen flex flex-col text-[#ccc] font-thin pt-32 px-10;
}

.section .container {
  @apply w-full flex flex-col p-5;
}
